<script>
    import {_, number} from 'svelte-i18n';
    import Starburst from '../lib/Starburst.svelte';
    import Countdown from '../lib/Countdown.svelte';
    import {Button} from 'svelte-chota';

    import ItemsListing from "./ItemsListing.svelte";
    import {collect_daily_subscription_route} from "../constants";
    import {getPackRarity, resourceDetails} from "../globals.js";

    import wssubdays7pack1 from '../assets/wssubdays7pack1.png?w=300&format=webp;jpg';
    import wssubdays7pack5 from '../assets/wssubdays7pack5.png?w=300&format=webp;jpg';
    import wssubdays14pack10 from '../assets/wssubdays14pack10.png?w=300&format=webp;jpg';
    import stpatrickwebshop20 from '../assets/stpatrickwebshop20.png?w=300&format=webp;jpg';
    import stpatrickwebshop300 from '../assets/stpatrickwebshop300.png?w=300&format=webp;jpg';

    export let subscription = {};

    let processing = false;
    let animate = false;
    let rarity = subscription.rarity;
    let goldBonus = subscription.goldBonus || '';
    let goldWorth = subscription.goldWorth || '';
    let goldAmount = subscription.rewards.gold;
    
    let headerImage = getHeaderImage();

    function getNextMidnightTime() {
        let midnight = new Date()
        midnight.setHours(24);
        midnight.setMinutes(0);
        midnight.setSeconds(0);
        midnight.setMilliseconds(0);

        return midnight.getTime()
    }

    // let collectedAt = new Date(subscription.collectedAt);
    // let remaining = Math.abs(getNextMidnightTime() - collectedAt);
    // let endDate = new Date(new Date().getTime() + remaining);

    export let globals = {};


    function formatTimeFriendly(seconds) {
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);
        let days = Math.floor(hours / 24);

        let timeString = `${minutes} ${$_('Minute')}`;

        if (seconds - 1 > 60 * 60 * 24) {
            timeString = `${days} ${$_('Days')}`;
        } else if (seconds > 60 * 60) {
            timeString = `${hours} ${$_('Hours')}`;
        } else if (seconds >= 60) {
            timeString = `${minutes} ${$_('Minutes')}`;
        }

        return timeString;
    }

    globals.resourceDetails = resourceDetails;
    globals.formatTimeFriendly = formatTimeFriendly;

    function getHeaderImage() {
        switch (subscription.bundleName) {
            case 'wssubdays7pack1':
                return wssubdays7pack1;
            case 'wssubdays7pack5':
                return wssubdays7pack5;
            case 'wssubdays14pack10':
                return wssubdays14pack10;
            case 'stpatrickwebshop20':
                return stpatrickwebshop20;
            case 'stpatrickwebshop300':
                return stpatrickwebshop300;
            default:
                return '';
        }
    }

    async function collectDailySubscription() {

        try {
            processing = true;
            const response = await fetch(collect_daily_subscription_route, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    packId: subscription.packId,
                }),
            });

            if (!response.ok) {
                const message = `An error has occured: ${response.status}`;
                throw new Error(message);
            }

            const res = await response.text();
            animate = true;
            // collectedAt = new Date();
            // remaining = Math.abs(getNextMidnightTime() - collectedAt);
            // endDate = new Date(new Date().getTime() + remaining);
            subscription.collected = true;
            // console.log(res)

        } catch (error) {
            Rollbar.error('Error collecting daily subscription: ' + error);
        } finally {
            processing = false;
        }
    }

    $: packRarity = getPackRarity(rarity);
</script>

<div class="pack free {subscription.collected ? '': 'active'} {packRarity} promos animate__animated {animate ? 'animate__rubberBand' : ''}">
    {#if subscription.showBonus}
        <Starburst>
            {$number(Math.floor((goldWorth + goldAmount + goldBonus) / (goldAmount + goldBonus)) * 100)}%
        </Starburst>
    {/if}
    <div class="content">
        <div class="header">
            <h2>{$_(subscription.bundleName + 'Title')}</h2>
            <span class="shadow"></span>
            <picture>
                <source srcset={headerImage[0]} type="image/webp">
                <source srcset={headerImage[1]} type="image/jpg">
                <img alt={$_(subscription.bundleName + 'Title')} class="pack-image" src={headerImage[0]}>
            </picture>
        </div>
        <div class="sub-header">
            <p>
                {@html $_('SubscriptionDescription', {
                    values: {
                        DAYS: '<b>' + subscription.rewards.days + '</b>',
                        GOLD_VALUE1: '<b>' + $number(subscription.rewards.gold * subscription.rewards.days) + '</b>',
                        GOLD_VALUE2: '<b>' + $number(goldWorth * subscription.rewards.days) + '</b>',
                    },
                })}
            </p>
        </div>

        <ItemsListing globals={globals} goldAmount={goldAmount} items={subscription.rewards.items} storePack={{}}/>
    </div>
    {#if subscription.collected}
        <span class="timer">{$_('SubscriptionPackCollectPopupInfo')}</span>
        <Button class="claim-button green" primary disabled>
            {$_('Collected')}
        </Button>
    {:else}
        {#if subscription.finishAt}
            <Countdown endDate={subscription.finishAt}>
                {$_('IncrementalPacksTimeLeft')}
            </Countdown>
        {/if}
        <Button class="claim-button green" primary loading={processing} on:click={collectDailySubscription}>
            {$_('Collect')}
        </Button>
    {/if}
</div>

<style lang="scss">
  :global {
    .pack {
      &.free {
        &.active {
          --border-width: 8px;
          z-index: auto;
          //border: none;
          //width: calc(100% - var(--border-width) * 2);
          //height: calc(100% - var(--border-width) * 2);

          &.legendary {
            background: hsl(46deg 100% 71% / 80%);

            .sub-header {
              background: linear-gradient(
                              60deg,
                              hsl(224, 85%, 66%),
                              hsl(269, 85%, 66%),
                              hsl(314, 85%, 66%),
                              hsl(0, 85%, 66%),
                              hsl(27, 70%, 56%),
                              hsl(34, 85%, 56%),
                              hsl(41, 85%, 56%),
                              hsl(36, 94%, 67%)
              );
              background-size: 300% 300%;
              background-position: 0 100%;
              animation: moveGradient 10s alternate infinite;
            }
          }

          &::after {
            position: absolute;
            content: "";
            top: calc(-1 * var(--border-width));
            left: calc(-1 * var(--border-width));
            z-index: -1;
            width: calc(100% + var(--border-width) * 2);
            height: calc(100% + var(--border-width) * 2);
            background: linear-gradient(
                            60deg,
                            hsl(224, 85%, 66%),
                            hsl(269, 85%, 66%),
                            hsl(314, 85%, 66%),
                            hsl(359, 85%, 66%),
                            hsl(44, 85%, 66%),
                            hsl(89, 85%, 66%),
                            hsl(134, 85%, 66%),
                            hsl(179, 85%, 66%)
            );
            background-size: 300% 300%;
            background-position: 0 50%;
            border-radius: calc(2 * var(--border-width));
            animation: moveGradient 10s alternate infinite;
            filter: blur(3px);
          }

          @keyframes moveGradient {
            50% {
              background-position: 100% 50%;
            }
          }

          &:nth-child(1):after, &:nth-child(1) .sub-header {
            animation-delay: .1s;
          }

          &:nth-child(2):after, &:nth-child(2) .sub-header {
            animation-delay: 1s;
          }

          &:nth-child(3):after, &:nth-child(3) .sub-header {
            animation-delay: .5s;
          }
        }


        .sub-header {
          p {
            font-size: 13px;
          }
        }

        .claim-button {
          &.green {
            height: 52px;
          }
        }
      }
    }

  }
</style>