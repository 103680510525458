<script>
    import {_, locale} from 'svelte-i18n';
    import Item from './Item.svelte';
    import {gameConfig} from "../../stores.js";

    export let item;
    export let gameItem;

    export let itemDetails = {};

    let baseName = getBaseName();

    function setItemDetails() {
        itemDetails.title = $_('choiceChest' + getBaseName() + 'Title');
        itemDetails.description = $_('choiceChestDescription');

        itemDetails.amount = item.amount;
        itemDetails.icon = getIcon();

        itemDetails.rarity = gameItem.rarity;
        itemDetails.expireAt = $gameConfig.temporaryItems[item.id];
        itemDetails.claimed = item.claimed;
    }

    function getBaseName() {
        switch (gameItem.iconId) {
            case "boosters":
                return 'Boosters';
            case "resources":
                return 'Resources';
            case "speedups":
                return 'SpeedUps';
            case "supplies":
                return 'Supplies';
            default:
                return 'Boosters';
        }
    }

    function getIcon() {
        return 'choiceChest' + baseName + "Icon";
    }

    locale.subscribe(setItemDetails);
</script>

<Item {itemDetails}/>
